export default {
    "home":"home",
    "about":"about",
    "skills":"skills",
    "portfolio":"portfolio",
    "contact":"contact",
    "blog": "blog",
    "home__title":"Hi, Welcome to my website!",
    "home__subtitle":"I'm a Frontend developer",
    "home__description":"High level experience in web design and development knowledge,producing quality work.",
    "home__contact":"Contact Me",
    "home__scroll-name":"Scroll down",
    "about__title":"About Me",
    "about__subtitle":"My introduction",
    "about__description":"Web front-end developer, has rich knowledge and experience, engaged in Web technology, technology is pure white, like to toss, can provide high quality work.",
    "about__info-name1":"Age",
    "about__info-name2":"Experience",
    "about__info-name3":"Companies <br /> worked",
    "download":"Download CV",
    "skills__title":"Skills",
    "skills__subtitle":"My technical level",
    "skills__years":"More than * years",
    "skills__title2":"Backend developer",
    "skills__years2":"More than * years",

    "qualification__title":"Qualification",
    "qualification__subtitle":"My personal journey",
    "education":"Education",
    "work":"Work",
    "qualification1__title":"Mechatronic Engineering",
    "qualification1__describe":"ChangSha - University",
    "qualification2__title":"Web Development",
    "qualification2__describe":"Self-Study",
    "qualification3__title":"Frontend Developer",
    "qualification3__describe":"ChangSha",

    "portfolio__title":"Portfolio",
    "portfolio__subtitle":"Most recent works",
    "portfolio1__title":"Modern Website 1",
    "portfolio1__description":"Website adaptable to all devices, with ui components and animated interactions.",
    "portfolio2__title":"Modern Website 2",
    "portfolio2__description":"Website adaptable to all devices, with ui components and animated interactions.",
    "portfolio3__title":"Modern Website 3",
    "portfolio3__description":"Website adaptable to all devices, with ui components and animated interactions.",
    "contact__title":"Contact me",
    "contact__subtitle":"Get in touch",
    "tel":"Call me",
    "tel__number":"18711003418",
    "email":"E-mail",
    "email__address":"2362414624@qq.com",
    "location":"Location",
    "location__detail":"HuNan , CHN"
}