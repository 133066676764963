export default {
    "home":"主页",
    "about":"关于",
    "skills":"技能",
    "portfolio":"项目集",
    "contact":"联系方式",
    "blog": "博客",
    "home__title":"Hi, Welcome to my website!",
    "home__subtitle":"前端工程师",
    "home__description":"热爱学习，热爱生活，创造更高质量工作成果。",
    "home__contact":"与我联系",
    "home__scroll-name":"往下滑",
    "about__title":"关于我",
    "about__subtitle":"简单介绍",
    "about__description":"Web前端开发人员，具有丰富的知识和经验，从事Web技术，技术崇拜，喜欢折腾，能提供高质量的工作。",
    "about__info-name1":"年龄",
    "about__info-name2":"工作经验",
    "about__info-name3":"公司 <br /> 任职",
    "download":"查看简历",
    "skills__title":"技能",
    "skills__subtitle":"技术水平",
    "skills__years":"*年工作经验",

    "skills__title2":"后端开发",
    "skills__years2":"*年工作经验",

    "qualification__title":"资历",
    "qualification__subtitle":"个人履历",
    "education":"教育",
    "work":"工作",
    "qualification1__title":"机械电子",
    "qualification1__describe":"长沙大学",
    "qualification2__title":"网站开发",
    "qualification2__describe":"自学",
    "qualification3__title":"前端开发工程师",
    "qualification3__describe":"长沙",

    "portfolio__title":"项目集",
    "portfolio__subtitle":"近期项目",
    "portfolio1__title":"网站1",
    "portfolio1__description":"网站采用响应式布局，适配于多种设备，具有UI组件和动画交互。",
    "portfolio2__title":"网站2",
    "portfolio2__description":"网站采用响应式布局，适配于多种设备，具有UI组件和动画交互。",
    "portfolio3__title":"网站3",
    "portfolio3__description":"网站采用响应式布局，适配于多种设备，具有UI组件和动画交互。",

    "contact__title":"联系方式",
    "contact__subtitle":"与我联系",
    "tel":"电话",
    "tel__number":"18711003418",
    "email":"电子邮箱",
    "email__address":"2362414624@qq.com",
    "location":"位置",
    "location__detail":"湖南省~长沙市"
    
}